@use './base';
@import 'styles/mixins';

.container {
  width: 100%;
  margin: 0 auto;
  background: #F5F7FB;
}

.company_info_wrapper {
  display: flex;
  justify-content: space-between;
}

.jobs_item {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0075em;
  color: #c9cdd4;
  padding: 16px;
  width: 346px;
  // height: 91px;
  border-radius: 8px;
  border: 1px solid #F2F3F5;
  background: #fff;
  transition: all 0.3s linear;

  &:hover {
    border: 1px solid #2378e5;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    color: #ffffff;

    .chat_now {
      opacity: 1;
      color: #ffffff;
    }

    .salary {
      opacity: 0;
    }
  }

  .header_box {
    display: flex;
  }
}


.jobs_item_layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab_layout {
  width: 1366 - 115 - 115px;
  margin: 0 auto;

  @include base.respond-to(phone) {
    padding-left: 0.04rem;
    width: 100%;
  }
}

.header {
  width: 100%;
  min-height: 140px;
  position: relative;
  background: linear-gradient(100deg, #DAEDFC 3.52%, #EFECFE 96.89%);
  padding-bottom: 36px;
  padding-top: 36px;

  @include base.respond-to(phone) {
    width: 100%;
    overflow: visible;
    margin-bottom: 0.32rem;
    padding: 0.36rem 0.2rem;
    height: auto;
  }
}

.header_info {
  position: relative;
  width: 1366px;
  margin: 0 auto;
  padding-left: 117px;
  // padding-top: 88px;

  display: flex;
  z-index: 3;

  @include base.respond-to(phone) {
    padding-left: 0;
    align-items: center;
    width: 100%;
  }
}

.header_logo {
  margin-right: 20px;

  @include base.respond-to(phone) {
    position: relative;
    // position: absolute;
    // z-index: 100;
    // bottom: -0.24rem;
    // left: 0.2rem;
    width: 0.49rem;
    height: 0.49rem;
    margin-right: 0.2rem;
    flex-shrink: 0;
  }
}

.header_logo_img {
  width: 80px;
  height: 80px;
  border-radius: 16px;
  object-fit: cover;
  border: 1px solid #ffffff;
  background-color: #fff;

  @include base.respond-to(phone) {
    width: 0.68rem;
    height: 0.68rem;
    border-radius: 0.13rem;
    border: 1px solid var(--neutral-color-border, #e5e6eb);
  }
}


.header_title_num_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-right: 40px;
}

.header_title_right {
  position: absolute;
  right: 100px;
  top: 16px;
  z-index: 3;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_title_num {
  color: #121212;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.24px;
  margin-right: 5px;
}

.header_title_string {
  color: #7D7D7D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.15px;
  position: relative;
  top: 2px;
}

.header_title {
  color: #121212;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-top: 6.5px;
  max-width: 800px;

  @include base.respond-to(phone) {
    color: #ffffff;
    font-size: 0.2rem;
    // width: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.25rem;
    margin-top: 0px;
  }
}

.header_subtitle {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  color: #515151;
  font-family: Product Sans;
  display: flex;

  @include base.respond-to(phone) {
    color: var(--neutral-color-text, #86909c);
    font-size: 0.13rem;
    font-family: Product Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 0.2rem;
    margin-top: 0.08rem;
    margin-bottom: 0;
    flex-wrap: wrap;
  }
}

.header_subtitle_item {
  @include base.split();
  flex-shrink: inherit;
  color: #515151;
  font-size: 16px;
}

.header_benefit_wrapper {
  display: flex;
  flex-wrap: wrap;

  @include base.respond-to(phone) {
    margin: 0 0.2rem;
    padding-bottom: 0.1rem;
  }
}

.header_benefit_item {
  flex-direction: row;
  align-items: center;
  padding: 7px 6px;
  gap: 3px;
  border-radius: 5px;
  color: #ffffff;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.0075em;
  text-align: center;
  margin-right: 4px;
  margin-bottom: 5px;
  background: rgba(139, 139, 139, 0.8);

  @include base.respond-to(phone) {
    color: var(--neutral-color-text, #4e5969);
    font-size: 0.13rem;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    font-family: Product Sans;
    font-style: normal;
    line-height: 0.18rem;
    text-align: center;
    margin-right: 0.04rem;
    margin-bottom: 0.05rem;
    background: var(--neutral-color-fill, #f7f8fa);
    margin-top: 10px;
    margin-bottom: 16px;
  }
}

.split {
  width: 100%;
  border-bottom: 1px solid var(--neutral-color-border, #e5e6eb);
}

.header_mask {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(180deg, rgba(7, 7, 7, 0.15) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.header_title_verified {
  margin-left: 20px;
  position: relative;
  top: 5px;

  @include base.respond-to(phone) {
    margin-left: 0.1rem;
  }
}

.footer {
  text-align: center;
  height: 85px;
  line-height: 85px;
}

.jobs_chat_now_container {
  position: relative;
  width: fit-content;
  min-width: 110px;
  padding-bottom: 10px;
}

.salary {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.0075em;
  color: #2378e5;
}

.jobs_content {
  display: flex;
  align-items: center;
}

.jobs_item_title {
  flex: 1;
  width: 0;
  height: 23px;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #1d2129;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  display: block;

  &:hover {
    color: #1d2129;
  }
}

.chat_now {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 73px;
  min-height: 28px;
  background: #2378e5;
  border-radius: 8px;
  color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 6px;
  opacity: 0;
  transition: all 0.3s linear;
  padding: 0 8px;
  white-space: nowrap;
}

.header_right {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #7D7D7D;

  &:hover {
    color: #2378E5;

    .arrow {
      border-left-color: #2378E5;
    }
  }
}

.arrow {
  border-left: 5px solid #7D7D7D;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin-left: 5px;
  width: 0;
  height: 0;
}

.more_job {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.0075em;
  text-decoration-line: underline;
  color: #1d2129;
}


// @include msd {
  .container {
    img {
      font-size: 0;
      border-radius: 0;
      border-radius: 9px;
      background-color: #7D7D7D;
    }

    color: #515151;
    font-size: 16px;
    line-height: 22px;

    .coverPic {
      width: 100%;
      height: auto;
    }

    .mobileHeader {
      padding: 28px 16px 20px 16px;
      background: radial-gradient(67.36% 69.53% at 15.11% 3.13%, #5E4218 0%, #1B1307 100%);

      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 65px;
        background-image: url('./components/assets/header_bottom_border.png');
        background-repeat: no-repeat;
        background-size: 100%;
      }

      .info {
        display: flex;
        justify-content: flex-start;

        .companyName {
          width: 100%;
          white-space: pre-wrap;
          /* 防止内容换行 */
          overflow: hidden;
          /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
          /* 超出部分显示省略号 */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* 显示两行 */
          -webkit-box-orient: vertical;
          color: #FDFDFE;
        }

        h1 {
          color: #ffffff;
          font-size: 20px;
          font-weight: 700;
          height: 48px;
          line-height: 24px;
        }

        .logo {
          width: 48px;
          height: 48px;
          margin-right: 12px;

          img {
            width: 48px;
            height: 48px;
          }
        }

      }

      .subtitle {
        color: #D5CCC1;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        padding-top: 20px;

        span {
          padding-right: 4px;
          position: relative;

          // &::after {
          //   content: ' ';
          //   width: 3px;
          //   height: 3px;
          //   border-radius: 50%;
          //   position: absolute;
          //   left: -8px;
          //   top: 8px;
          //   background-color: #515151;
          // }

          &:first-child {
            &::after {
              display: none;
            }
          }
        }

        .itemRightIcon {
          vertical-align: middle;
        }

        .more {
          float: right;
          color: #D5CCC1;

          .moreIcon {
            margin-left: 4px;
            vertical-align: middle;
          }
        }
      }

      .tagGroup {
        width: 100%;
        margin-top: 12px;
        overflow-x: auto;
        word-break: keep-all;
        white-space: nowrap;

        .tagItem {
          display: inline-block;
          background: #493D34;
          color: #D5CCC1;
          font-size: 12px;
          margin-right: 6px;
          margin-bottom: 4px;
          padding: 4px 10px;
          border-radius: 4px;
          word-break: keep-all;
          white-space: nowrap;
        }
      }
    }

    // .mobileHeaderBottomBorder {
    //   height: 51px;
    //   background-image: url('./components/assets/header_bottom_border.png');
    //   background-color: #1B1307;
    //   background-repeat: no-repeat;
    //   background-size: 100%;
    // }
  }
// }