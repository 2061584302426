/* stylelint-disable font-family-no-missing-generic-family-keyword */
.headerRoot {
  .headerTopBg {
    position: absolute;
    top: 8px;
    left: 8px;
    align-self: stretch;
    width: calc(100% - 18px);
    height: 67px;
    background: #edf5ff;
    border-radius: 6px 6px 0 0;
  }

  .companyLogo {
    $size: 90px;

    position: relative;
    width: $size;
    height: $size;
    border-radius: 8px;
  }

  .companyName {
    display: flex;
    gap: 12px;
    align-items: center;
    align-self: stretch;

    // margin-top: 20px;
    font-family: "SF Pro Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #121212;
  }

  .companySizeWrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    /* WEB/Heading/Subtitle3-medium */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
    color: var(--Global-Alpha-Black-Black, #000);

    .dot {
      position: relative;
      flex-shrink: 0;
      width: 1.5px;
      height: 1.5px;
      background-color: #000;
      border-radius: 50%;
    }
  }

  .jobAndBoss {
    display: flex;
    gap: 12px;
    align-items: center;
    align-self: stretch;

    /* H5/Caption/Caption-Bold */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
    color: var(--neutral-color-text-06121212, #121212);

    .unit {
      margin-left: 4px;

      /* WEB/Heading/Subtitle3-medium */
      font-family: "SF Pro Display";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      color: #000;
    }

    .splitter {
      width: 1px;
      height: 14px;
      background-color: #dbdfe4;
    }
  }

  .horLine {
    height: 0.5px;
    margin: 22px 0;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .emailAndPhone {
    display: flex;
    gap: 24px;

    .emailAndPhoneItem {
      display: flex;
      gap: 6px;
      align-items: center;

      /* H5/Caption/Caption-Bold */
      font-family: "SF Pro Display";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 137.5% */
      color: var(--neutral-color-text-06121212, #121212);
    }

    .moreBtn {
      /* WEB/Heading/Subtitle3-medium */
      font-family: "SF Pro Display";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--text-icon-07121212, #111212);
      cursor: pointer;
    }
  }
}

.stickyTabRoot {
  position: sticky;
  top: 0;
  z-index: 1;

  &.isAttached {
    position: sticky;
    background: #edf5ff;
    box-shadow: 0 2px 10px 0 rgba(96, 112, 163, 0.05);

    .tabRoot {
      background-color: transparent;
    }
  }
}

.phoneModalContent {
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: flex-start;
  width: 420px;
  padding: 20px 32px 32px;
  background: #fff;
  border-radius: 16px;

  .header {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;

    /* WEB/Heading/H5 */
    font-family: "SF Pro Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    color: #000;
  }

  .closeIcon {
    cursor: pointer;
  }

  .listItem {
    display: flex;
    gap: 6px;
    align-items: center;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
