/* stylelint-disable selector-class-pattern */
/* stylelint-disable scss/at-mixin-pattern */

.mask {
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.25);
}

.bottomBorder {
  border-bottom: 1px solid #e4e4e4;
}

.bannerWrapper {
  position: relative;
  width: calc(100% + 48px);
  height: 200px;
  margin: -24px 0 30px -24px;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
}

.user {
  display: flex;

  :global {
    & .ant-image {
      align-items: center;
      width: 80px;
      height: 80px;

      & .ant-image-img {
        width: 100%;
        height: 100%;
      }

      & .ant-image-mask-info {
        display: none;
      }

      & .ant-image-mask {
        border-radius: 50%;
      }
    }
  }

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .infoWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    overflow: hidden;
  }

  .userName {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .jobTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #515151;
  }
}

.sevenDay {
  margin: 30px 0 20px;
  font-size: 20px;
  font-weight: 400;
  color: #121212;
}

// padding: 30px 30px 0 30px;
.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    color: #121212;
    letter-spacing: 0.15px;
  }
}

.medal {
  .badgeWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0 20px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }

  .badgeItem {
    padding: 12px;
    background: #edf0f3;
    border-radius: 8px;
  }
}

.company {
  width: 100%;

  .companyContent {
    display: flex;
    gap: 20px;

    @media (max-width: 767px) {
      gap: 10px;
    }
  }

  .companyLogo {
    width: 60px;
    height: 60px;
    border-radius: 15px;
  }

  .companyName {
    display: block;
    margin-bottom: 2px;
    overflow: hidden;
    font-size: 24px;
    font-weight: 700;
    color: #121212;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .labelBox {
    padding-top: 8px;
  }

  .label {
    padding: 0 8px;
    color: #7d7d7d;

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid #7d7d7d;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
  letter-spacing: 0.75px;
}

.rootCls {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 700px;
  max-width: 100%;
  padding: 24px;
  white-space: normal;
  background: #fff;
  border-radius: 8px;
  transform: translate(-50%, -50%);

  &.hasBadges {
    top: 20px;
    transform: translate(-50%, 0);
  }

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    padding: 4px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    max-width: 90%;
    max-height: calc(100vh - 40px);
    padding: 12px;
    overflow-y: scroll;
  }
}
