/* stylelint-disable font-family-no-missing-generic-family-keyword */
.reportBtn {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 8px;

  // margin-bottom: 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
  cursor: pointer;
  background-color: #fff;
}

.reminderRoot {
  display: flex;
  flex-direction: column;
  padding: 20px 24px 32px;
  background: #f5f9ff;
  border-radius: 16px;

  .header {
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: var(--text-icon-054-b-4-c-4-d, #4b4c4d);
    text-align: center;
  }

  .bodyText1 {
    margin: 16px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: var(--neutral-color-text-05515151, #515151);
  }

  .bodyText2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--neutral-color-text-05515151, #515151);
  }

  :global {
    .aLikeText {
      // margin-left: 3px;
      text-decoration-line: underline;
      cursor: pointer;
    }

    .aLikeText:hover {
      color: #000;
    }
  }

  .list {
    margin-top: 0;
  }

  .listItem {
    display: flex;
    gap: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    color: var(--neutral-color-text-05515151, #515151);
    list-style: none;
  }

  .dotWrapper {
    display: flex;
    align-items: center;
    height: 22px;
  }
}

.btnALike {
  /* H5/Caption/Caption-Med */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  color: var(--neutral-color-text-05515151, #515151);
}
