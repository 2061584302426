/* stylelint-disable custom-property-pattern */
/* stylelint-disable font-family-no-missing-generic-family-keyword */

.jobPageRoot {
  padding: 28px 16px;
  background-color: #fff;
}

.topSearchRoot {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 20px;
  background-color: #fff;

  .filterBtn {
    display: flex;
    flex: 1 0 0;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #515151;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
  }
}

.jobNameWrapper {
  position: relative;

  // display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jobItem {
  position: relative;
  display: block;
  padding: 16px 12px;
  background: var(--Flowkit-White, #fff);

  // background: #fff;
  // border: 1px solid var(--line-border-02-f-4-f-5-f-6-secondary, #f4f5f6);
  // border-radius: 12px;

  border-radius: 12px;

  /* Normal Shadow Styles/Normal Shadow-md */
  // box-shadow: 0px 4px 6px 0px rgba(162, 183, 203, 0.10);

  /* Normal Shadow Styles/Normal Shadow-lg */
  box-shadow: 0 5px 8px -2px rgba(162, 183, 203, 0.1);

  &:not(:first-child) {
    margin-top: 8px;
  }

  .jobItemTitle {
    font-family: "SF Pro Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    color: #000;

    &:hover {
      color: #136fd3;
    }
  }

  .jobItemSalary {
    position: relative;
    font-family: "SF Pro Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #1c70ee;
  }
}

.jobItemTags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  color: var(--neutral-color-text-05515151, #515151);

  .dot {
    display: inline-block;
    width: 2px;
    height: 2px;
    background-color: #7d7d7d;
    border-radius: 50%;
  }
}

.jobItemBottomWrapper {
  display: flex;
  gap: 8px;

  // height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .dot {
    display: inline-block;
    margin: 0 8px;

    &::after {
      position: absolute;
      top: 20px;
      display: inline-block;
      width: 2px;
      min-width: 2px;
      max-width: 2px;
      height: 2px;
      content: " ";
      background-color: #7d7d7d;
      border-radius: 50%;
    }
  }
}

.horLine {
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: #ebeef0;
}

.chatNowBtn {
  min-width: 78px;
  padding: 7px 18px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  text-align: right;
  letter-spacing: 0.0075em;
  background: #2378e5;
  border-radius: 8px;
}

.jobItemAvatar {
  position: relative;

  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
}

.jobItemRecruiterName {
  display: inline;
  color: #515151;
}

.jobItemRecruiterRole {
  display: inline;
  color: #7d7d7d;
}

.statusRow {
  display: flex;
  gap: 4px;
  align-items: center;

  /* WEB/Heading/Subtitle4-medium */
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #34c759;

  &.offline {
    color: #b8b8b8;
  }
}

.filterNumber {
  min-width: 20px;
  height: 16px;

  /* WEB/Caption/Caption-Sem */
  font-family: "SF Pro Display";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  color: var(--brand-color-050066-ff, #1c70ee);
  text-align: center;
  background: rgba(0, 102, 255, 0.1);
  border-radius: 10px;
}

.emptyNode {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;

  /* WEB/Body/Body2-regular */
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  color: var(--global-neutral-14202021, #202021);
  text-align: center;
}
